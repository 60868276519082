<template>
    <el-container>
        <el-container>
            <!-- 中间内容 -->
            <el-main>
                <h2 class="depTitle">科室管理</h2>
                <div class="RightDiv">
                    <el-form :model="searchForm" class="searForm">
                        <div class="table-title">
                            <div>
                                <el-input v-model="searchForm.nameLike" placeholder="科室名称" clearable prefix-icon="el-icon-search" @keyup.enter.native="searchList" @blur="searchList" @clear="searchList">
                                </el-input>
                            </div>
                            <div class="index-add">
                                <el-button type="primary" @click="depaddKeshi">添加科室</el-button>
                            </div>
                        </div>
                    </el-form>
                </div>
                <el-table :data="tableData" border center>
                    <el-table-column type="index" label="序号" width="55" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="科室名称" width="350" align="center">
                    </el-table-column>
                    <el-table-column prop="updateDate" label="更新时间" align="center">
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="250">
                        <template style="display: flex" slot-scope="scope">
                            <el-button size="mini" type="primary" @click="setKeshi(scope.row)"><i class="el-icon-user-solid"></i></el-button>
                            <el-button size="mini" type="primary" @click="editKeshi(scope.row)"><i class="el-icon-edit"></i></el-button>
                            <el-button size="mini" type="danger" @click="deleteKeshi(scope.row)"><i class="el-icon-delete-solid"></i></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
                </el-pagination>
            </el-main>
        </el-container>
        <!-- 点击添加科室弹出框 -->
        <el-dialog title="添加科室" :visible.sync="AdddialogVisible" width="360px" :before-close="AdddialogVisibleClose">
            <el-form :model="AddDateModel" ref="AddDateRef" :rules="AddDateRules" label-width="100px">
                <el-form-item label="科室名称：" prop="name">
                    <el-input v-model="AddDateModel.name" placeholder="请填写科室"></el-input>
                </el-form-item>
                <el-form-item class="depar-dia">
                    <div class="btnCen">
                        <el-button v-if="addDepClick" type="primary" :loading="addDepClickKing"   @click="addKeshi">添加</el-button>
                        <el-button v-else type="info" :loading="addDepClickKing"></el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 点击修改科室弹出框 -->
        <el-dialog title="修改科室" :visible.sync="EditdialogVisible" width="360px" :before-close="EditdialogVisibleClose">
            <el-form :model="EditDateModel" ref="EditDateRef" :rules="EditDateRules" label-width="100px">
                <el-form-item label="科室名称：" prop="name">
                    <el-input v-model="EditDateModel.name" placeholder="请填写科室名称"></el-input>
                </el-form-item>
                <el-form-item class="depar-dia">
                    <div class="btnCen">
                        <el-button v-if="editDepClick" type="primary" :loading="editDepClickKing" @click="editdialogKeshi">修改</el-button>
                        <el-button v-else type="info" :loading="editDepClickKing"></el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 点击科室用户管理 -->
        <el-dialog title="科室用户管理" :visible.sync="SetdialogVisible" width="960px" :before-close="SetdialogVisibleClose">
            <div>
                <div class="AddUserDiv">
                    <el-button class="AddUser" type="primary" @click="AddUser">添加科室用户</el-button>
                </div>
                <el-table :data="UsertableData" style="width: 95%; margin: 0 auto" border center>
                    <el-table-column type="index" label="序号" width="55" align="center">
                    </el-table-column>
                    <el-table-column prop="nickName" label="姓名" align="center">
                    </el-table-column>
                    <el-table-column prop="phonenumber" label="手机号" align="center">
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="200">
                        <template style="display: flex" slot-scope="scope">
                            <el-button size="mini" type="primary" @click="EditUser(scope.row)"><i class="el-icon-edit"></i></el-button>
                            <el-button size="mini" type="danger" @click="DeleteUser(scope.row)"><i class="el-icon-delete-solid"></i></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentUserChange" :current-page="pageUserNum" :page-size="pageUserSize" layout="  prev, pager, next" :total="totalUser">
                </el-pagination>
            </div>
        </el-dialog>
        <!-- 添加科室用户 -->
        <el-dialog title="添加科室用户" :visible.sync="AdduserdialogVisible" width="400px" :before-close="AdduserdialogVisibleClose">
            <el-form :model="AddUserModel" ref="AddUserRef" :rules="AddUserRules" :inline="true" label-width="100px">
                <el-form-item label="姓名：" prop="nickName">
                    <el-input v-model="AddUserModel.nickName" placeholder="请填写姓名"></el-input>
                </el-form-item>
                <el-form-item label="手机号：" prop="phonenumber">
                    <el-input type="phone" v-model="AddUserModel.phonenumber" placeholder="请填写手机号"></el-input>
                </el-form-item>
                <el-form-item label="数据维护：" class="depart-addnum">
                    <el-switch v-model="AddUserModel.dataAuth" active-value="0" inactive-value="1" active-color="#13ce66" inactive-color="#ff4949">
                    </el-switch>
                </el-form-item>
                <el-form-item label="绩效权限：" class="depart-addnum">
                    <el-switch v-model="AddUserModel.performanceAuth" active-value="0" inactive-value="1" active-color="#13ce66" inactive-color="#ff4949">
                    </el-switch>
                </el-form-item>
                <el-form-item class="depar-dia">
                    <div class="btnCen">
                        <el-button v-if="adduserClick" type="primary" :loading="adduserLoading"  @click="adduserkeshi">添加</el-button>
                        <el-button v-else type="info" :loading="adduserLoading"></el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 修改科室用户 -->
        <el-dialog title="修改科室用户" :visible.sync="EdituserdialogVisible" width="400px" :before-close="EdituserdialogVisibleClose">
            <el-form :model="EditUserModel" ref="EditUserRef" :rules="EditUserRules" :inline="true" label-width="100px">
                <el-form-item label="姓名：" prop="nickName">
                    <el-input v-model="EditUserModel.nickName" placeholder="请填写姓名"></el-input>
                </el-form-item>
                <el-form-item label="手机号：" prop="phonenumber">
                    <el-input type="phone" v-model="EditUserModel.phonenumber" placeholder="请填写手机号"></el-input>
                </el-form-item>
                <el-form-item label="数据维护：" class="depart-addnum">
                    <el-switch v-model="EditUserModel.dataAuth" active-value="0" inactive-value="1" active-color="#13ce66" inactive-color="#ff4949">
                    </el-switch>
                </el-form-item>
                <el-form-item label="绩效权限：">
                    <el-switch v-model="EditUserModel.performanceAuth" active-value="0" inactive-value="1" active-color="#13ce66" inactive-color="#ff4949">
                    </el-switch>
                </el-form-item>
                <el-form-item class="depar-dia">
                    <div class="btnCen">
                        <el-button v-if="editUserClick" type="primary" :loading="editUserLoading" @click="edituserkeshi">修改</el-button>
                        <el-button v-else type="info" :loading="editUserLoading"></el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
    </el-container>
</template>

<script>
import _qs from "qs";
export default {
    data() {
        var checkAge = (rule, value, callback) => {
            if (!value && String(value) !== "0") {
                return callback(new Error("不能为空"));
            }
            setTimeout(() => {
                if (!/^1[3|4|5|7|8][0-9]\d{8}$/.test(value)) {
                    callback(new Error("请输入正确的手机号"));
                } else {
                    callback();
                }
            }, 100);
        };
        return {
            // 添加科室的添加按钮
            addDepClick: true,
            addDepClickKing: false,
            // 添加用户的添加按钮
            adduserLoading: false,
            // 查询用的业务名称
            searchForm: {
                nameLike: "",
            },
            // 添加科室用户的添加
            adduserClick: true,
            // 修改科室用户的修改按钮
            editUserClick: true,
            editUserLoading: false,
            // 修改科室用户
            EditUserModel: {
                // 名称
                nickName: "",
                // 手机号
                phonenumber: "",
                // 是否有数据维护 0 选择 1 不选
                dataAuth: "",
                // 是否有绩效权限 0 选择 1 不选
                performanceAuth: "",
            },
            EditUserRules: {
                nickName: [
                    {
                        required: true,
                        message: "请输入科室用户名称",
                        trigger: "blur",
                    },
                ],
                phonenumber: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
            },
            // 添加科室用户
            AddUserModel: {
                // 名称
                nickName: "",
                // 手机号
                phonenumber: "",
                // 是否有数据维护 0 选择 1 不选
                dataAuth: "",
                // 是否有绩效权限 0 选择 1 不选
                performanceAuth: "",
            },
            AddUserRules: {
                nickName: [
                    {
                        required: true,
                        message: "请输入科室用户名称",
                        trigger: "blur",
                    },
                ],
                phonenumber: [
                    { required: true, validator: checkAge, trigger: "blur" },
                ],
            },
            // 添加科室
            AddDateModel: {
                // 科室名称
                name: "",
            },
            AddDateRules: {
                name: [
                    {
                        required: true,
                        message: "请输入科室名称",
                        trigger: "blur",
                    },
                ],
            },
            // 修改科室
            EditDateModel: {
                // 科室名称
                name: "",
            },
            EditDateRules: {
                name: [
                    {
                        required: true,
                        message: "请输入科室名称",
                        trigger: "blur",
                    },
                ],
            },
            // 数据维护开关
            phonenumber: 1,
            // 绩效权限开关
            performanceAuth: 0,
            // 点击添加科室弹出框
            AdddialogVisible: false,
            // 修改科室弹出框
            EditdialogVisible: false,
            // 科室用户管理
            SetdialogVisible: false,
            // 添加科室用户
            AdduserdialogVisible: false,
            // 修改科室用户
            EdituserdialogVisible: false,
            // 科室管理的分页
            // 当前页数
            pageNum: 1,
            // 每页显示条数
            pageSize: 10,
            // 总数
            total: 1,
            // 科室用户管理的分页
            // 当前页数
            pageUserNum: 1,
            // 每页显示条数
            pageUserSize: 10,
            totalUser: 1,
            // 表格数据
            tableData: [],
            // 科室用户管理表格
            UsertableData: [],
            // 医院id
            hospitalId: window.sessionStorage.getItem("hospitalId"),
            // 科室id
            departmentId: window.sessionStorage.getItem("departmentId"),
            // 科室用户点击修改的id
            userId: "",
            keshiid: "",
            // 科室id
            keshiUserid: "",
            // 修改科室的修改按钮
            editDepClick: true,
            editDepClickKing: false,
        };
    },
    created() {
        this.getDepartmentList();
    },
    methods: {
        // 搜索框
        searchList() {
            this.getDepartmentList();
        },
        // 科室管理分页
        handleCurrentChange(val) {
            this.pageNum = val;
            // console.log(`当前页: ${val}`);
            this.getDepartmentList();
        },
        handleCurrentUserChange(val) {
            this.pageUserNum = val;
            // console.log(`当前页: ${val}`);
        },
        // 点击添加科室
        depaddKeshi() {
            this.AdddialogVisible = true;
        },
        // 点击添加科室的时候取消正则
        AdddialogVisibleClose() {
            this.AdddialogVisible = false;
            this.$refs.AddDateRef.resetFields();
        },
        // 点击操作的修改科室时候取消正则
        EditdialogVisibleClose() {
            this.EditdialogVisible = false;
            this.$refs.EditDateRef.resetFields();
        },
        // 点击添加科室用户时候取消正则
        AdduserdialogVisibleClose() {
            this.adduserLoading = false;
            this.AdduserdialogVisible = false;
            this.$refs.AddUserRef.resetFields();
        },
        // 点击科室用户管理的修改时候取消正则
        EdituserdialogVisibleClose() {
            this.editUserLoading = false;
            this.EdituserdialogVisible = false;
            this.$refs.EditUserRef.resetFields();
        },
        // 点击操作添加科室的添加
        addKeshi() {
            this.$refs.AddDateRef.validate((valid) => {
                if (valid) {
                    this.addDepClick = false;
                    this.addDepClickKing = true;
                    let data = _qs.stringify({
                        hospitalId: this.hospitalId,
                        name: this.AddDateModel.name,
                    });
                    this.addDepartment(data);
                }
            });
        },
        // 点击操作删除
        async deleteKeshi(row) {
            const confirmResult = await this.$confirm("确认删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).catch((err) => err);

            if (confirmResult !== "confirm") {
                return this.$message.info("已经取消删除");
            }
            let data = _qs.stringify({
                ids: row.id,
            });
            let { data: res } = await this.$axios.removeDepartment(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "科室中存在用户，不能删除。",
                    type: "error",
                });
            } else if (res.code == 200) {
                this.$message({
                    message: "删除成功",
                    type: "success",
                });
                this.getDepartmentList();
            }
        },
        // 点击操作修改科室的添加
        editKeshi(row) {
            this.keshiid = row.id;
            this.EditDateModel.name = row.name;
            this.EditdialogVisible = true;
        },
        SetdialogVisibleClose() {
            this.pageUserNum = 1;
            this.SetdialogVisible = false;
        },
        // 点击操作的科室用户管理
        async setKeshi(row) {
            this.keshiUserid = row.id;
            this.SetdialogVisible = true;
            let data = {
                hospitalId: this.hospitalId, //医院id
                departmentId: row.id, //科室id
                pageNum: this.pageUserNum, //页数
                pageSize: this.pageUserSize, //每页显示条数
            };
            let { data: res } = await this.$axios.getDepartmentUserList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.UsertableData = res.rows;
                this.totalUser = res.total;
            }
        },
        // 点击修改科室用户管理的添加
        editdialogKeshi() {
            this.$refs.EditDateRef.validate((valid) => {
                if (!valid) return;
                this.editDepClick = false;
                this.editDepClickKing = true;
                let data = _qs.stringify({
                    name: this.EditDateModel.name, //科室名称
                    hospitalId: this.hospitalId, //医院id
                    id: this.keshiid, //科室id
                });
                this.addDepartment(data);
            });
        },
        // 添加或修改科室信息接口
        async addDepartment(data) {
            let res = await this.$axios.addDepartment(data);
            // console.log(res);
            this.addDepClick = true;
            this.addDepClickKing = false;
            this.editDepClick = true;
            this.editDepClickKing = false;
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "添加/修改科室失败",
                    type: "error",
                });
            } else if (res.status == 200) {
                this.EditdialogVisible = false;
                this.AdddialogVisible = false;
                // 添加科室
                this.AddDateModel = {
                    // 科室名称
                    name: "",
                };
                this.$message({
                    message: "添加/修改科室成功",
                    type: "success",
                });
                this.getDepartmentList();
            }
        },
        // 点击添加科室用户
        AddUser() {
            this.AdduserdialogVisible = true;
        },
        // 修改科室用户
        EditUser(row) {
            this.userId = row.userId;
            this.EditUserModel = row;
            this.EdituserdialogVisible = true;
        },
        // 删除科室用户
        async DeleteUser(row) {
            const confirmResult = await this.$confirm("确认删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).catch((err) => err);

            if (confirmResult !== "confirm") {
                return this.$message.info("已经取消删除");
            }
            let data = _qs.stringify({
                userIds: row.userId,
            });
            let { data: res } = await this.$axios.removeDepartmentUser(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "删除失败",
                    type: "error",
                });
            } else if (res.code == 200) {
                this.$message({
                    message: "删除成功",
                    type: "success",
                });
                let keshiUseridRow = { id: this.keshiUserid };
                this.setKeshi(keshiUseridRow);
            }
        },
        //点击添加科室用户的添加
        adduserkeshi() {
            this.$refs.AddUserRef.validate(async (valid) => {
                if (!valid) return;
                this.adduserClick = false;
                this.adduserLoading = true;
                let data = _qs.stringify({
                    nickName: this.AddUserModel.nickName, //名称
                    phonenumber: this.AddUserModel.phonenumber, //手机号
                    dataAuth: this.AddUserModel.dataAuth, //是否有数据维护 0 选择 1 不选
                    performanceAuth: this.AddUserModel.performanceAuth, //是否有绩效权限 0 选择 1 不选
                    departmentId: this.keshiUserid, //科室id
                });
                this.addAndEdit(data);
            });
        },
        //点击修改科室用户的添加
        edituserkeshi() {
            this.$refs.EditUserRef.validate((valid) => {
                if (!valid) return;
                this.editUserClick = false;
                this.editUserLoading = true;
                let data = _qs.stringify({
                    nickName: this.EditUserModel.nickName, //名称
                    phonenumber: this.EditUserModel.phonenumber, //手机号
                    dataAuth: this.EditUserModel.dataAuth, //是否有数据维护 0 选择 1 不选
                    performanceAuth: this.EditUserModel.performanceAuth, //是否有绩效权限 0 选择 1 不选
                    userId: this.userId, //用户id
                    departmentId: this.keshiUserid, //科室id
                });
                this.addAndEdit(data);
            });
        },
        // 添加或修改科室用户接口
        async addAndEdit(data) {
            let res = await this.$axios.addAndEdit(data);
            // console.log(res);
            this.adduserClick = true;
            this.adduserLoading = false;
            this.editUserClick = true;
            this.editUserLoading = false;
            if (res.data.code == 500) {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            } else if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.status == 200) {
                this.$message({
                    message: "添加/修改科室用户成功",
                    type: "success",
                });
                let keshiUseridRow = { id: this.keshiUserid };
                this.setKeshi(keshiUseridRow);
                this.AdduserdialogVisible = false;
                this.EdituserdialogVisible = false;
                this.AddUserModel = {};
            }
        },
        // 分页显示科室管理数据
        async getDepartmentList() {
            let data = {
                hospitalId: this.hospitalId, //登录后返回的 医院id
                nameLike: this.searchForm.nameLike, //查询用的 科室名称
                pageNum: this.pageNum, //页数
                pageSize: this.pageSize, //每页显示条数
                orderByColumn: "updateDate", //排序参数 updateDate 更新时间
                isAsc: "desc", //asc 升序 desc降序
            };
            let { data: res } = await this.$axios.getDepartmentList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.tableData = res.rows;
                this.total = res.total;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.RightDiv {
    margin: 0 auto;
}
.searForm {
    float: right;
}
.depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 20px;
}
.depart-addnum {
    width: 100%;
}

.AddUser {
    float: right;
    margin-bottom: 10px;
}

.AddUserDiv {
    width: 95%;
    margin: 0 auto;
}

/deep/ .depar-dia .el-form-item__content {
    margin-left: 0px !important;
}

.btnCen {
    .el-button {
        width: 150px;
        height: 40px;
    }
}

.depar-dia {
    width: 100%;
}

.collapse-btn {
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    background: rgb(134 140 146);
}
/deep/ .el-radio-button__inner {
    width: 100px;
}
.el-pagination {
    margin-top: 20px;
}
.el-main {
    .table-title {
        display: flex;
        margin-bottom: 10px;
        .index-add {
            margin-left: 20px;
        }
    }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}
</style>
